<mat-dialog-content class="mat-typography">
  <div class="row" style="display: flex; justify-content: center;align-items: center;margin-top: 1rem;">
    <!-- <img src="assets/images/addmission_banner_2024.jpeg"> -->

    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <!-- <div class="carousel-item active">
                <img class="d-block w-100" src="../../../assets/images/Dr Reddy.jpg" alt="Second slide">
                <div style="display: flex;justify-content: center;margin-top: 5px;">
                  <a href="http://tinyurl.com/PRPOTEPharmaReddyDrive" target="_blank" class="btn btn-primary">View More</a>
                </div>
              </div> -->
              <div class="carousel-item active">
                <img class="d-block w-100" src="../../../assets/images/flyer/1.jpg" alt="Second slide">
                <div style="display: flex;justify-content: center;margin-top: 1rem;">
                  <a href="https://tinyurl.com/5d9cmzn6" target="_blank" class="btn btn-danger">
                    D.Pharm, B.Pharm, M.Pharm Admission Enquiry</a>
                </div>
              </div>
      
              <div class="carousel-item ">
                <img class="d-block w-100" src="../../../assets/images/flyer/2.jpg" alt="Second slide">
                <div style="display: flex;justify-content: center;margin-top: 1rem;">
                  <a href="https://tinyurl.com/5d9cmzn6" target="_blank" class="btn btn-danger">
                    D.Pharm, B.Pharm, M.Pharm Admission Enquiry</a>
                </div>
              </div>
        <div class="carousel-item ">
          <img class="d-block w-100" src="../../../assets/images/poster/poster-1.jpeg" alt="Second slide">
          <div style="display: flex;justify-content: center;margin-top: 1rem;">
            <a href="https://tinyurl.com/5d9cmzn6" target="_blank" class="btn btn-danger">
              D.Pharm, B.Pharm, M.Pharm Admission Enquiry</a>
          </div>
        </div>
        <div class="carousel-item ">
          <img class="d-block w-100" src="../../../assets/images/poster/poster-2.jpeg" alt="Second slide">
          <div style="display: flex;justify-content: center;margin-top: 1rem;">
            <a href="https://tinyurl.com/5d9cmzn6" target="_blank" class="btn btn-danger">
              D.Pharm, B.Pharm, M.Pharm Admission Enquiry</a>
          </div>
        </div>
        <div class="carousel-item ">
          <img class="d-block w-100" src="../../../assets/images/poster/poster-3.jpeg" alt="Second slide">
          <div style="display: flex;justify-content: center;margin-top: 1rem;">
            <a href="https://tinyurl.com/5d9cmzn6" target="_blank" class="btn btn-danger">
              D.Pharm, B.Pharm, M.Pharm Admission Enquiry</a>
          </div>
        </div>
       
       
        <!-- <div class="carousel-item ">
                <img class="d-block w-100" src="../../../assets/images/home-banner/ravishankar.jpeg" alt="Second slide">
              </div> -->
        <!-- <div class="carousel-item ">
                <img class="d-block w-100" src="../../../assets/images/home-banner/pharmacolon.jpg" alt="Second slide">
              </div> -->
        <!-- <div class="carousel-item ">
                <img class="d-block w-100" src="../../../assets/images/home-banner/banner-1.jpeg" alt="Second slide"> <br>
                <div style="display: flex;justify-content: center;">
                  <a href="https://drive.google.com/drive/folders/1iDywKuOXi1TTwV9aNALirIlXQTTeG8Br?usp=sharing" target="_blank" class="btn btn-primary">View More</a>
                </div>
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/banner-2.jpeg" alt="Second slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/banner-3.jpeg" alt="Second slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/World pharmacist day 2023.jpeg" alt="First slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/1.jpeg" alt="Second slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/2.jpeg" alt="Third slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/3.jpeg" alt="Forth slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/4.jpeg" alt="Fifth slide">
              </div> -->
      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</mat-dialog-content>