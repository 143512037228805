<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 2rem">
        <div class="news_content news_buletin_pra">
            <h1><a href="#">SWAYAM PORTAL</a></h1>
            <hr>

            <div class="row">
                <div class="col-md-3">
                    <img src="../../assets/images/flyer/2.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/pdf/swayam_cert.jpeg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/1.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/2.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/3.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/4.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/5.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/6.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/7.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/8.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/9.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../assets/images/swayam/10.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
            </div>
        </div>
    </div>
</div>