<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 2rem">
        <div class="news_content news_buletin_pra">
            <div class="news_content news_buletin_pra">
                <h1><a href="#">
                        Details of Copyrights (IPR Cell)
                    </a></h1>
                <hr>

                <div class="row">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>S N</td>
                                <td>Title</td>
                                <td>Dairy No.</td>
                                <td>Name of Faculty</td>
                                <td>Year</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Nanoparticle for Brain Targeting</td>
                                <td>L-148142/2024</td>
                                <td>Dr. Dipti B. Ruikar</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Ethyl cellulose water solubility impact on drug release profile</td>
                                <td>L-148134/2024</td>
                                <td>Dr. Dipti B. Ruikar</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Formulation of Chewable Tablet For Halitosis</td>
                                <td>L-147697/2024</td>
                                <td>Mr. Prashant J. Burange</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Ethyl cellulose water solubility impact on drug release profile</td>
                                <td>L-148134/2024</td>
                                <td>Dr. Prasad Deshmukh</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Nanoparticle for Brain Targeting</td>
                                <td>L-148142/2024</td>
                                <td>Dr. Bhushan Bhoyar</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Nanoparticle for Brain Targeting</td>
                                <td>L-148142/2024</td>
                                <td>Dr. Prasad Deshmukh</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Ethyl cellulose water solubility impact on drug release profile</td>
                                <td>L-148134/2024</td>
                                <td>Dr. Bhushan Bhoyar</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Development And Evaluation of Polyherbal Gel For Mouth Ulcer</td>
                                <td>L-137719/2023</td>
                                <td>Dr. Dipti B. Ruikar</td>
                                <td>2023</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Introduction of Biopharmaceutics</td>
                                <td>L-138025/2023</td>
                                <td>Dr. Prasad Deshmukh</td>
                                <td>2023</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Plasticizers and Its Utility</td>
                                <td>L-133042/2023</td>
                                <td>Dr. Bhushan Bhoyar</td>
                                <td>2023</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Pharmacokinetics-Importance of Absorption</td>
                                <td>L-130545/2023</td>
                                <td>Ms. Dipti Bonde</td>
                                <td>2023</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Formulation And Development of Carbopol Gel Containing Antifungal Drug Loaded
                                    Nanosponges</td>
                                <td>L-134137/2023</td>
                                <td>Ms. Seema Karwade</td>
                                <td>2023</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Pharmacological Screening of Antiulcer Drugs</td>
                                <td>L-130631/2023</td>
                                <td>Ms. Dipti Bonde</td>
                                <td>2023</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>Application of Diclofenac Sodium Containing Transdermal Patch</td>
                                <td>14044/2024-CO/L</td>
                                <td>Amruta Sanjayrao Phuse</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>Compressed Coated Tablet</td>
                                <td>12127/2024-CO/L</td>
                                <td>Dnyanesha Narendra Somnathe</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>Development and evaluation of formulation from Calotropis gigantea Linn leaf
                                    extract
                                </td>
                                <td>14614/2024-CO/L</td>
                                <td>Gauri Vilas Raut</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>Formulation and Evaluation of Herabl anti-inflammatory gel of fruit extract
                                    Lagenaria siceraria</td>
                                <td>14296/2024-CO/L</td>
                                <td>Neha Madavi</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>Formulation and evaluation of topical semisolid preparation of Ehretia Laevis
                                    Roxb
                                    (Khandu Chakka)</td>
                                <td>14796/2024-CO/L</td>
                                <td>Nishigandha Kakde</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>Formulation and evaluation of neutraceutical using herbal extract</td>
                                <td>14256/2024-CO/L</td>
                                <td>Prajakta More</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>Development and evaluation of formulation from Ficus racemosa Linn bark extract
                                </td>
                                <td>14737/2024-CO/L</td>
                                <td>Tejaswi Kohale</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>21</td>
                                <td>Solubility Enhancement of NSAID Drug using Advanced Crystallization Technique
                                </td>
                                <td>10178/2024-CO/L</td>
                                <td>Vaishnavi Borgaonkar</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>22</td>
                                <td>Formulation and Evaluation of In Situ Gel</td>
                                <td>13548/2024-CO/L</td>
                                <td>Sangam Sanjay Nimkar</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>23</td>
                                <td>Multiparticulate Drug Delivery System</td>
                                <td>9843/2024-CO/L</td>
                                <td>Shobhit Kishor Suralke</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>24</td>
                                <td>Development And Validation of Advanced analytical method for estimation of drugs
                                    in
                                    bulk &amp; in their combination.</td>
                                <td>10107/2024-CO/L</td>
                                <td>Achal Harinarayan Yelane</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>25</td>
                                <td>A study of Trending QMS elements of GXP in Pharmaceutical Industries</td>
                                <td>16935/2024-CO/L</td>
                                <td>Maithili Amale</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>26</td>
                                <td>Method Development and Validation of Antidiabetic Drug in it&#39;s
                                    Pharmaceutical
                                    Dosage Form</td>
                                <td>11622/2024- CO/L</td>
                                <td>Papiha S. Tekade</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>27</td>
                                <td>Diagrammatic Representation for Principle and Instrumentation of UV-Visible
                                    Spectrophotometer</td>
                                <td>14782/2024-CO/L</td>
                                <td>Vaishnavi Bhagavat</td>
                                <td>2024</td>
                            </tr>
                            <tr>
                                <td>28</td>
                                <td>A High Performance Liquid Chromatography Method Development For Lefunomide
                                    Analysis
                                    and Validation</td>
                                <td>10548/2024-CO/L</td>
                                <td>Siddhant Madhukar Sawant</td>
                                <td>2024</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            
            <h1><a href="#">COPYRIGHTS</a></h1>
            <hr>

            
            <div class="row">
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/1.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/2.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/3.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/4.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/5.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/6.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/7.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/8.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/9.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/10.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
                <div class="col-md-2" style="margin-top: 1rem;">
                    <a href="../../../assets/images/copyrights/11.pdf" target="_blank">
                        <img src="../../../assets/images/pdf_logo.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>